/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "app/satoshi";
@import "app/general";
@import "app/popups";

ion-icon {
  &[class*="prefix-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }
  // custom icons

  &[class*="prefix-pass-eye-on"] {
    color: #000000;
    mask-image: url(/assets/sign_up_sign_in/password-icon.png);
  }

  &[class*="prefix-top_up_my_balance"] {
    background: url(/src/assets/custom-icons/topupmybalance.svg) no-repeat center center;
    background-size: contain;
  }

  &[class*="prefix-balance_history"] {
    background: url(/src/assets/custom-icons/balancehistory.svg)no-repeat center center;
    background-size: contain;
  }

  &[class*="prefix-statements"] {
    background: url(/src/assets/custom-icons/statements.svg)no-repeat center center;
    background-size: contain;
  }

  &[class*="prefix-manage_top_up_limits"] {
    background: url(/src/assets/custom-icons/toplimits.svg)no-repeat center center;
    background-size: contain;
  }

  &[class*="prefix-withdraw"] {
    background: url(/src/assets/custom-icons/withdraw.svg)no-repeat center center;
    background-size: contain;
  }
}





