

.tooltip-overlay {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0,0,0, 0.4);
  z-index: 999;

}

.tooltip-example {
  text-align: center;
  padding: 0 50px;
}
.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}
.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 10px 15px;
  //background: rgba(249, 243, 252, .9);
  background: linear-gradient(180deg, rgba(253, 223, 234, 0.2) 0%, rgba(255, 255, 255, 0) 64.42%), #320455;
  box-shadow: 0px 16px 43px rgba(3, 0, 157, 0.11), 0px 22px 134px rgba(79, 0, 128, 0.13);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;

  .tooltip-title {
    font-family: 'Satoshi-Medium';
  }
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #2d0049 transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #2d0049 transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent #2d0049;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent #2d0049 transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}


.tooltip-title {
  font-size: 12px;
  padding-bottom: 10px;
  .tooltip-winner {
    font-size: 12px;
  }

  .my-user {
    color: rgba(96,192,69, 1);
  }
}



* {
  font-family: 'Satoshi-Regular';
}

.header-md::after, .tabs-md[tabsPlacement="top"] > .tabbar::after, .footer-md::before, .tabs-md[tabsPlacement="bottom"] > .tabbar::before {
  background-image: none;
}

ion-content {
  border-top: 1px solid #ddd;
}

.list-md {
  background: transparent;
}


.item-wrapper-with-shadow {
  border: 4px solid #FFFFFF;
  box-shadow: 0px 12px 20px rgba(3, 0, 157, 0.06), 0px 22px 64px rgba(49, 0, 79, 0.28);
}

.item-wrapper {
  background: #FFFFFF;
  box-sizing: border-box;
  //border: 2px solid #EBE7EE;
  border-radius: 10px;



  .item {
    --background: none;
    --border-style: 0;
    &:last-child {
      border-bottom: 0!important;
      box-shadow: none!important;
    }

    ion-label {
      --color: #9100FF!important;
      color: #9100FF!important;
      font-weight: bold;
    }

    input {
      --placeholder-color: #4d4d4d;
      color: #320455;
    }



    .item-native {

    }
    .label {
      font-family: 'Satoshi-Bold';
      font-weight: bold;
      font-size: 12px;
      color: #9100FF;
    }

    .text-input {
      font-weight: bold;
      font-size: 14px;
      color: #320455;
    }
  }

  .datetime {
    .datetime-text {
      font-weight: bold;
      font-size: 14px;
      color: #320455;
    }
  }
}

.input-pseudo-label {
  font-size: 16px;
  color: #320455;
  padding-bottom: 5px;
}

.signIn-header, .header-with-back-btn-and-balance {
  padding: 20px 0 20px 0;
  .back-button {
    color: #9100FF;
    z-index: 100;
  }
  ion-title {
    color:#320455;
    font-size: 24px;
    font-weight:bold;
    .toolbar-title {

    }
  }
}

ion-title {
  position: absolute;
  width:100%;
  left:0;
  top:10px;
}

.tab-header {
  ion-title {
    //margin-left: 0%;
  }
  .balance-wrapper {
    position: absolute;
    right: 0;
    top: 7px;
  }
}

.content-padding {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 26px;
  --padding-end: 26px;

}

.item-wrapper {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #EBE7EE;
  border-radius: 10px;
}

.input-pseudo-label {
  font-size: 16px;
  color: #320455;
  padding-bottom: 5px;
}

.submit-button {
  --background: linear-gradient(119.88deg, #5913F3 1.69%, #871DC6 85.51%);
  background: linear-gradient(119.88deg, #5913F3 1.69%, #871DC6 85.51%);
  //--box-shadow: 0px 16px 43px rgba(3, 0, 157, 0.47);
  --color: #fff;
  color: #fff;
  font-weight: normal;
  //padding: 21px 40px !important;
  font-size: 15pt;
  text-transform: capitalize;
  height: 50px;
  width:100%;
  text-align: center;
  --border-radius: 10px;
  border-radius: 10px;
}

.submit-button[disabled] {
  --opacity: 1;
  --background: #BAAFC2;
  background: #BAAFC2;
  --border-radius: 10px;
  --box-shadow: none;
}

.descr-color {
  color: #FF5500;

  a {
    color: #FF5500!important;
  }
}

.progress-line-wrapper {
  padding:0 27px;
  border-radius: 100px;
  .progress-line {
    background: rgba(50, 4, 85, 0.1);
    width: 100%;
    height: 8pt;
    border-radius: 100px;

    .progress-line-element {
      background: linear-gradient(119.88deg, #5913F3 1.69%, #871DC6 85.51%);
      height: 8pt;
      border-radius: 100px;
    }
  }
}

.nc_card{
  //overflow: visible;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 28px;
  background: #FFFFFF;
  box-shadow: 0px 10px 32px rgba(4, 0, 198, 0.08), 0px 22px 134px rgba(79, 0, 128, 0.13);
  border-radius: 32px;
}

.cards_information {
  text-align: center;
  color: rgba(50, 4, 85, 0.3);
  font-size:15px;
  padding: 5px 0;
  span {
    font-family: 'Satoshi-Bold';
  }
}


.current-prize-pot-wrap {
  padding: 20px 12% 26px 12%;
  .current-prize-pot {
    background: linear-gradient(0deg, #FFE502, #FFE502);
    border-radius: 13px;
    padding: 1px 0;
    .title {
      color:#320455;
      text-align: left;
      padding-left: 3%;
      font-size: 16px;
    }

    .prize {
      color:#320455;
      font-weight:bold;
      text-align: right;
      font-size: 16px;
      padding-right: 5%;
    }
  }


  .current-prize-pot-opacity {
    background: linear-gradient(0deg, #FFFAE6, #FFFAE6);
  }
}

.purchase_button {
  background: rgba(132, 28, 202, 0.06);
  color: #9100FF;
  display: block;
  width:100%;
  height:70px;
  font-family: 'Satoshi-Bold';
  font-size: 18px;
  font-weight: bold;

  .button-text {
    padding: 5px 0 0 0;
  }
}

.nc_list {
  contain: inherit;
}


.noMessagesWrap {
  position: absolute;
  top: 10%;
  width:100%;
  left:0;

  .noIcon {
    background: #fff url("../assets/cards/19421.png") no-repeat center;
    background-size: 67%;
    width:25px;
    height:25px;
    position: absolute;
    left:50%;
    margin-left: -12.5px;
    top:-8px;
    border-radius: 50%;
    padding: 17px;
  }
  .noMessages {
    background: rgba(235, 231, 238, 0.5);
    border-radius: 10px;
    color: #BAAFC2;
    text-align:center;

    span {
      text-transform: lowercase;
    }
  }
}

app-items-detail-page{
  //background: darken(color($colors, primary), 12%) !important;
  background: url("../assets/games/buy_cards_background.jpg") no-repeat top center;
  background-size: contain;
}

.buy_card_header {
  ion-toolbar {
    .toolbar-background-md {
      background: none;
    }

    .back-button {
      color: #fff;
    }

    ion-title {
      color: #fff;
    }
  }
}

.buy_card_header {
  .b_heading {
    .bal-text {
      color: #fff;
    }
  }
}

// ALERT POPUP
.alert-wrapper{
  //background-color: lighten( color($colors, primary), 10% ) !important;
  //color: color($colors, primary_text) !important;
  border-radius: 5px !important;

  .alert-title {
    text-align: center;
    color:#320455;
    font-family: 'Satoshi-Bold';
  }

  .alert-sub-title {
    text-align: center;
    color:#320455;
  }

  .alert-button-group {
    text-align: center;
    .alert-button {
      margin-left: auto;
      margin-right: auto;
      color:#320455;
      font-family: 'Satoshi-Bold';
      font-weight: bold;
    }
  }
}

app-livecard, app-home {
  .balance-wrapper {
    app-balance {
      ion-button {
        .bal-text {
          color:#fff!important;
          font-family: 'Satoshi-Black';
        }
      }
    }

  }
}

.ion-card-relative {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.game-status-area {
  text-align: center;
  width: 110px;
  margin: 0 auto;
  position: relative;
  top:32px;
  z-index: 1;
  height:36px;

  .color_red, .color_closed, .color_scheduled {
    border-radius: 200px;
    color: #fff;
    padding:5px;
  }

  .color_red {
    background: #FC2054;

    .live {
      width:7px;
      height: 7px;
      background: #fff;
      border-radius: 100%;
      display: inline-block;
      position: relative;
      top:-2px;
      left: -7px;
    }
  }

  .color_closed{
    background: #320455;
  }

  .color_scheduled{
    background: #4d4d4d;
  }

  .color_gold {
    background: linear-gradient(180deg, #FFE600 0%, #FFC700 100%);
    border: 2px solid #FF9900;
    box-shadow: 0px 0px 40px rgba(251, 226, 0, 0.4), 0px 3px 8px rgba(255, 122, 0, 0.4);
    border-radius: 200px;
    padding:5px;
    font-weight:bold;
    font-family: 'Satoshi-Black';
  }
}

.teams-board {
  //background: #38383c;
  //box-shadow: 0 0 5px #252424;
  //border-radius: 5px 5px 0 0;


  .teamboard-header {
    border-bottom: 1px solid #757474;
    .txt {
      color: #fff;
      text-align: left;
    }

  }


  .teamboard-content {
    padding: 30px 10px 0 10px;
    .team-logo {
      width:67px;
      margin: 0 auto;
    }

    .team-name {
      color:#320455;
      font-family: 'Satoshi-Bold';
      position:relative;
      font-size: 16px;
      word-wrap: normal;
      span {
        white-space: nowrap;
        //color: #f4be3f;
      }
    }

    .game_name {
      color: #BAAFC2
    }

    .left-team {
      text-align: center;
      width: 112px;
      left: -15px;
    }

    .right-team {
      text-align: center;
      width: 112px;
      right: 17px;
    }

    .score_wrapper {
      //position: relative;
      //top:10px;
      margin-top:10px;
    }

    .score-col {
      padding:0;
    }

    .score-title {
      color: #BAAFC2;
      font-size: 18px;
    }

    .team-score {
      color:#320455;
      //background: #326779;
      //border-radius: 5px;
      //position:relative;
      //top:18px;
      font-size: 50px;
      //display: inline-block;
      //padding: 0px 5px;
      word-wrap: normal;
      //width:50px;
      display: block;
      text-align: center;
      position: relative;
      top:-15px;
    }

    .label-all-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .game_name {
      color: #BAAFC2;
      position: absolute;
      top:5px;
    }

    .labels-wrap{

      .period-label {
        font-size: 18px;
        color: #BAAFC2;

      }

      .game-time {
        color: #320455;
        font-size: 50px;
        position: relative;
        top:-10px;
      }
    }

    .clock_timer {
      //padding: 0 5px;
      color:#BAAFC2;
      font-size: 20px;
      position: relative;
      top: 12px;
    }
  }

  .padding-params {
    padding: 0px 10px 0 10px;
  }
}

.events-block-header {
  background: #F9F3FC;
  color: #320455;
  font-family: 'Satoshi-Bold';
  padding: 15px 0 15px 25px;
  font-size: 18px;
  text-align: left;
}

.no-events-block {
  position: relative;
  top: 10%;
  width:100%;
  left:0;
  padding:20px;
  .smile {
    position: absolute;
    top:0px;
    left: 50%;
    margin-left: -12.5px;
    background: #fff url("../assets/page_images/cards/19421.png") no-repeat center;
    background-size: 67%;
    width:25px;
    height:25px;
    border-radius: 50%;
    padding: 17px;
  }

  .text {
    background: rgba(235, 231, 238, 0.5);
    border-radius: 10px;
    color: #BAAFC2;
    text-align:center;
    padding: 20px;
    font-family: 'Satoshi-Regular';
    color:#BAAFC2;
  }
}

.noMessagesWrap {
  position: absolute;
  top: 10%;
  width:100%;
  left:0;

  .noIcon {
    background: #fff url("../assets/page_images/cards/19421.png") no-repeat center;
    background-size: 67%;
    width:25px;
    height:25px;
    position: absolute;
    left:50%;
    margin-left: -12.5px;
    top:-8px;
    border-radius: 50%;
    padding: 17px;
  }
  .noMessages {
    background: rgba(235, 231, 238, 0.5);
    border-radius: 10px;
    color: #BAAFC2;
    text-align:center;

    span {
      text-transform: lowercase;
    }
  }
}

.events-block {
  position:relative;
  .teamboard-events {
    //border-top: 1px solid #757474;


    .teamboard-events-scroll {
      height:118px!important;
      overflow-y:auto;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }


      ::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #d0d0d0;
        border-radius: 10px;
      }

      .scroll-content {
        padding:0;
      }

      .event-item {
        padding-left: 20px;
        padding-right: 20px;
      }
      .event-text {
        color: #320455;
        padding-bottom: 9px;
        border-bottom: 1px solid rgba(132, 28, 202, 0.05);
        .teamImages {
          display: inline-block;
          margin-right: -20px;
          width: 60px;
          vertical-align: middle;
          .eventsTimelineTeamLogo {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-radius: 6px;
            top: 3px;
            position: relative;
          }

          .coverLogo {
            position: relative;
            left: -17px;
          }
        }

        .label {
          position: relative;
          //top: -5px;
          width: 80%;
          display: inline-block;
          vertical-align: middle;
        }

        .event-icon {
          display:block;
          width: 108px;
          height:132px;
          position: absolute;
          right:2px;
          top:0;
          transform: scale(0.25);
          margin-top: -50px;
          margin-right: -30px;
        }
      }
    }

    .eventsFeedComingSoon {
      text-align: center;
      position: relative;
    }
  }

  .feedSpinner {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    background: rgba(0,0,0, 0.4);
    z-index: 10;
    ion-spinner {
      position: absolute;
      top:50%;
      left:50%;
      margin: -14px 0 0 -14px;
    }
  }
}

.ad-wrapper {
  //background: #38383c;
  //box-shadow: 0 0 5px #252424;
  border-radius: 5px;
  padding: 0 35px;
  margin-bottom: 20px;
  .adTitle, .adDescription {
    color: #fff;
    text-align: left;
    padding:5px 0;
  }

  .adDescription {
  }
}

.alert-head {
  color:#000;
}

.balance-area {
  text-align:right;
  background: #2d2f32;
  balance .b_heading {
    padding: 5px 10px 5px 0;
    margin:0;
    color:#fff;
  }
}

.big-balance-color {
  .b_heading {
    padding-top: 30px;
    height:53px;
    margin-top: 25px;
  }
  .bal-text {
    //background: red;
    font-size: 50px;
    font-weight:bold;
    -webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
  }
  .yellowBalance {
    background: linear-gradient(180deg, #7D00C9 -20.69%, #320455 125.86%);
    font-size: 50px;
    font-weight:bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .pound-icon {
    display: none!important;
  }
}

.information-block {
  //padding: 20px;
  h2 {
    padding:0;
    margin:0;
    color: #320455;
    font-size: 20px;
    background: #F9F3FC;
    font-family: 'Satoshi-Bold';
    span {
      color: #f1c34f;
      font-family: 'Satoshi-Bold';
    }
  }

  h4 {
    color: #f1c34f;
  }

  .cloud-block-wrap {
    padding: 0 20px;
    .cloud-block {
      background: #E9FAED;
      color: #0B7E2B;;
      margin: 0 10px;
      padding: 16px 20px 16px 60px;
      border-radius: 10px;
      margin: 0 0 10px 0;
      position: relative;
      box-shadow: none;


      .lamp {
        position: absolute;
        width: 20px;
        height:20px;
        top:22px;
        left: 20px;
        background: url("../assets/page_images/account/lamp.png");
        background-size: contain;
      }
    }
  }

  .block_head {
    color: #320455;
    background: #F9F3FC;
    padding:10px 20px;
    font-family: 'Satoshi-Bold';
    //font-weight: bold;

    border-top: 2px solid #ECE0F5;
    border-bottom: 2px solid #ECE0F5;
  }


}

.section_button {
  //background: #2d2f32;
  color: #320455;
  padding: 0 5px 0 0px;
  border-bottom: 2px solid #ECE0F5;
  --detail-icon-color:rgba(145, 0, 255, 1);
  --detail-icon-opacity: 1;
  --detail-icon-font-size: 25px;



  .item-inner {
    //background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2012%2020'><path%20d='M2,20l-2-2l8-8L0,2l2-2l10,10L2,20z'%20fill='%23c8c7cc'/></svg>");
    //background-image: url("../assets/page_images/arrow.svg");

    padding: 10px 32px 10px 20px;
    //padding-right: 32px;
    //padding-left: 20px;
    background-position: right 14px center;
    background-position: right calc(14px + constant(safe-area-inset-right)) center;
    background-position: right calc(14px + env(safe-area-inset-right)) center;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border-bottom: 0!important;

    ion-icon {
      position: relative;
      top:4px;
    }
  }
}

.with_icon {
  ion-label{
    padding-left: 13px;
  }
}

/*  ajax loader */

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  background: rgba(0,0,0, 0.5);
  z-index: 999999;

  .su_spinner {
    text-align: center;
    width: 30px;
    height: 30px;
    color:#fff;
    position: absolute;
    top: 50%;
    left:50%;
    margin: -15px 0 0 -15px;
  }
}

.cards_block_wrap {
  ion-segment {
    ion-segment-button {
      border: 0px solid #000;
      //background: rgba(255,255,255, .5);
      color: #320455;
      font-family: Satoshi-Bold;
      margin: 0 3px;
      height:35px;
      line-height: 35px;
      min-width: auto;
      --color-checked: #320455;
    }

    .segment-button-checked {
      background: #F9F3FC;
      //color: #320455;
      //--color:#320455;

      font-family: Satoshi-Bold;
    }
  }
}

.dont-contact-me {
  .checkbox {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;



    &:hover input ~ .checkmark {
      //background-color: #ccc;
      //border:
      border: 1px solid #DEB3FF;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

    }

    input:checked ~ .checkmark {
      background-color: transparent;
    }

    input:checked + label .checkmark {
      border: 2px solid #DEB3FF;
    }

    input:checked ~ label .checkmark:after {
      display: block;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 40px;
      width: 40px;
      background: transparent;
      border: 2px solid rgb(159, 159, 158);
      border-radius: 5px;



      &:after {
        left: 14px;
        top: 5px;
        width: 8px;
        height: 18px;
        border: solid #9100FF;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:after {
        content: "";
        position: absolute;
        display: none;
        //color: #fff;
        //background-color: #fff;
      }
    }

    label {
      position: relative;
      top: -12px;
      left: -6px;
      font-size:13px;
      color: rgb(255, 255, 255);
      display: block;

      .label-text {
        display: block;
        padding-left:53px;
        padding-top: 3px;
        color:#320455;
        font-size: 15px;
      }
    }
  }
}

.winnings-list-wrap {
  ion-card {
    margin:0;
    padding:0 16px!important;
    width:100%;
    box-shadow: none;
    background: none;
    border-bottom: 2px solid #ECE0F5;
    .wind-date {
      color:#BAAFC2;
      padding-top: 5px;
      span {
        text-transform: capitalize;
      }
    }

    .date {
      color: #320455;
      font-size: 17px;
    }

    .info {
      color: #320455;
      .type {
        text-transform: uppercase;
      }
    }

    .teams {
      color: #320455;
      font-size:12px;
      padding:10px 0;
      .dep-success {
        color: #2dd36f;
      }
    }

    .price {
      text-align: right;
      position: relative;
      top:32px;
      color: #9100FF;
      font-family: 'Satoshi-Bold';
      span {
        color: #DEB3FF;
        font-family: 'Satoshi-Bold';
      }


    }
  }
}

.mb-list-card{
  padding: 25px 0px;
  text-align: left !important;
  background-color: #F9F3FC;
  color: #320455;
  font-family: 'Satoshi-Bold';
  border-radius: 2px;
}

.row-wrap {
  ion-col {
    padding:0;
    padding-right:10px;
    .sign-up-button {
      padding:0;
    }

    .sign-up-button {
      box-shadow: none;
      //border-radius: 20px;
    }

    .cancel-background {
      --background: #F4E9FA;
      background: #F4E9FA;
      --color:#9100FF;
      color:#9100FF;
      //box-shadow: 0px 16px 43px rgba(3, 0, 157, 0.47);
    }
  }

  ion-col+ion-col {
    padding-left:10px;
    padding-right:0px;
  }
}

.sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: var(--ion-color-step-550, #320455)!important;
}

[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md .alert-radio-inner {
  background-color: var(--ion-color-step-550, #320455);
}

.game-status-area-games {
  width: 156px;
  font-size: 14px;
}




