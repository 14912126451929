.popupOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 10;
}

.god_off {
  width: 91%;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;

  .closeBtn {
    color: #5b5c60;
    background: none;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 12;
    font-size: 19px;
  }
}


.popup-cont{
  position: relative;
  overflow: hidden;
  color: #8a4182;
  font-weight: 800;
  background: rgba(51, 52, 57, 1);
  padding: 10px;
  border-radius: 4px;
  border: 0px;
  border-color: #ccc;
  border-style: solid;
  display: block;

  h2 {
    size: 28pt;
    color: rgb(251,180,0);
    text-align: center;
    padding-bottom: 10px;
  }

  .fullTime {
    padding-bottom: 20px;
  }

  .gameOver {
    padding: 20px 0 30px 0;
    color:rgb(251,180,0);
    text-align: center;
  }
}


.popup-animation {
  animation: godAnim ease-out 0.8s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: godAnim ease-out 0.8s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: godAnim ease-out 0.8s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: godAnim ease-out 0.8s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: godAnim ease-out 0.8s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}


.popup-header{
  color: rgb(170, 170, 170);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  padding-top: 15px;
}

.winners-block-title {
  text-transform: uppercase;
  color: rgba(251,180,0, 0.5);
  font-size: 10pt;
}

.popup-details-cont{
  padding: 0px 15px 15px 15px;

  h1 {
    text-align: center;
    font-size: 24pt;
    color: rgb(251, 180, 0);
  }

  .popup-wins-table{
    width:100%;
    margin-bottom: 15px;

    .popup-item{
      color: #243362 !important;
      font-weight: 600;
      font-size: 13px !important;

      .popup-wins-left{
        text-align: left;
        color: #fff;
        font-weight: 400;
        font-size: 16pt !important;

      }

      .popup-wins-right{
        color: #fab700;
        font-size: 16pt;
        background: rgb(34,35,38);
        border-radius: 4px;
        padding: 5px;
        text-align: center;

      }
    }
  }


  .textItems {
    color: #fff;
    font-size: 11pt;
    font-weight: normal;
    padding: 10px 0;
  }

  ::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 10px;
  }


  .winner-wrapper {

    .winners-block {
      background: rgb(34,35,38);
      border-radius: 4pt;
      -webkit-border-radius: 4pt;
      padding: 10px;
      margin-bottom: 8pt;


      .winner-block-row {
        .col{
          padding:0;
          color: rgba(255,255,255, 0.5);
          font-size: 12pt;
          &:first-child {
            font-size: 10pt;
          }

          span {
            color: rgb(251,180,0);
            padding-right: 3px;
          }
        }
      }

      .higlightMyLine {
        .winners-block-title {
          color: rgba(251,180,0, 1);
        }
      }

      .higlightMe {
        .col{
          padding:0;
          color: rgba(96,192,69, 1);
        }
      }
    }




  }

  .winnerOneItem {
    height: 74.3px;
  }

  .winnerTwoItem {
    height: 148.6px;
  }

  .winnerThreeItem {
    height:223px;
  }

  .total-pot-row {
    .col {
      color: #fff;
      font-size: 11pt;
      font-weight: normal;
      padding: 10px;
    }

    &:first-child {
      font-size: 10pt;
    }
  }


}

.popup-button {
  background-color: rgb(251, 180, 0);
  color: #000;
  font-weight: normal;
  padding: 21px 40px !important;
  font-size: 15pt;
  height: 50px;
  text-align: center;
  border-radius: 5px;
}




@keyframes godAnim {
  0% {
    opacity: 0;
    transform: translate(300px, 0px) skewX(-30deg);
  }
  60% {
    opacity: 1;
    transform: translate(-40px, 0px) skewX(30deg);
  }
  80% {
    opacity: 1;
    transform: translate(0px, 0px) skewX(-15deg);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px) skewX(0deg);
  }
}

@-moz-keyframes godAnim {
  0% {
    opacity: 0;
    -moz-transform: translate(300px, 0px) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -moz-transform: translate(-40px, 0px) skewX(30deg);
  }
  80% {
    opacity: 1;
    -moz-transform: translate(0px, 0px) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px) skewX(0deg);
  }
}

@-webkit-keyframes godAnim {
  0% {
    opacity: 0;
    -webkit-transform: translate(300px, 0px) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate(-40px, 0px) skewX(30deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px) skewX(0deg);
  }
}

@-o-keyframes godAnim {
  0% {
    opacity: 0;
    -o-transform: translate(300px, 0px) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -o-transform: translate(-40px, 0px) skewX(30deg);
  }
  80% {
    opacity: 1;
    -o-transform: translate(0px, 0px) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px) skewX(0deg);
  }
}

@-ms-keyframes godAnim {
  0% {
    opacity: 0;
    -ms-transform: translate(300px, 0px) skewX(-30deg);
  }
  60% {
    opacity: 1;
    -ms-transform: translate(-40px, 0px) skewX(30deg);
  }
  80% {
    opacity: 1;
    -ms-transform: translate(0px, 0px) skewX(-15deg);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px) skewX(0deg);
  }
}


