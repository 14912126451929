// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Satoshi-Regular';


  /** primary **/
  --ion-color-primary: #fff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #fff;
  --ion-color-primary-tint: #fff;

  /** secondary **/
  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #fff;
  --ion-color-secondary-tint: #fff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}



app-tutorial {
  .swiper-pagination {
    bottom: 6% !important;

    .swiper-pagination-bullet {
      margin: 0 17px !important;
      width: 10px;
      height: 10px;
      background: #fff;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: #E83657;
      top: 0px;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #E83657;
        border-radius: 50%;
        position: relative;
        left: -3px;
        top: -3px;
      }
    }
  }
}


app-home {

  ion-slides {
    height:180px;
  }

  .swiper-pagination-bullets {
    //bottom: -25%!important;
    bottom: 5px!important;
    .swiper-pagination-bullet {
      margin: 0 15px!important;
      width: 10px;
      height: 10px;
      background: #fff;
      opacity:1;
      top: 5.5px;
      position: relative;
    }
    .swiper-pagination-bullet-active {
      background: #E83657;
      top: 6px;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border:1px solid #E83657;
        border-radius: 50%;
        position:relative;
        left: -3px;
        top: -3px;
      }
    }
  }

  .game-dates-filter-wrapper {
    ion-segment {
      --background:  linear-gradient(0deg, #EBE7EE, #EBE7EE), #FFFFFF;
      ion-segment-button.segment-button-layout-icon-top {
        //background:
        font-size: 14px;
        color:  #BAAFC2;
        font-weight: bold;
        opacity: 1;
        border-bottom-width: 0;
        display: inline!important;
        .button-inner {
          border:1px solid red;
        }
        span {
          display: inline!important;
          padding-right: 5px;
        }
      }

      ion-segment-button.segment-button-layout-icon-top::part(native) {
        flex-direction: initial;
      }

      .segment-button-checked {
        background: #fff;
        color:#000!important;

      }
    }
  }



}

.game_period {
  background: rgba(229, 229, 229, 1);
  color:rgba(50, 4, 85, 1);
  padding: 4px 0;
  text-align: center;
}

.nc_button_cont{
  width: 100%;
  //background-color: darken(color($colors, primary),12%);
  //padding-top: 10px;
  //padding-bottom: 6px;
  border-top: 1px solid rgba(145, 0, 255, 0.3);
}



